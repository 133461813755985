<template>
    <base-content>
        <template #button>
            <a-button type="primary" @click="modal_visible = true">新增物料预警</a-button>
        </template>
        <a-modal v-model:visible="modal_visible" title="新增物料预警" :width="720" @ok="submit">
            <a-form :labelCol="{ span: 4 }" :wrapperCol="{ span: 18 }">
                <a-form-item label="预警形式">
                    <a-select v-model:value="mode">
                        <a-select-option value="总库存数量">总库存数量</a-select-option>
                        <a-select-option value="全批次数量">全批次数量</a-select-option>
                        <a-select-option value="单批次数量">单批次数量</a-select-option>
                        <a-select-option value="单批次时间">单批次时间</a-select-option>
                    </a-select>
                    <template #extra>
                        <span v-if="mode == ''">选择预警模式后可查看说明。</span>
                        <span v-if="mode == '总库存数量'">针对某种物料所有批次库存之和设置预警，当总库存数量低于该值时进行提醒。</span>
                        <span v-if="mode == '全批次数量'">针对某种物料所有批次库存设置预警，当其中任意批次库存低于该值时进行提醒。</span>
                        <span v-if="mode == '单批次数量'">针对某种物料某一批次库存设置预警，当其库存低于该值时进行提醒。</span>
                        <span v-if="mode == '单批次时间'">针对某种物料某一批次设置有效期预警，当时间接近该日期时进行提醒。</span>
                    </template>
                </a-form-item>
                <a-form-item label="选择物料" :extra="material_extra" v-if="mode != ''">
                    <a-input-search v-model:value="material_code" placeholder="输入物料编码" @search="$refs.SelectTotalMaterial.start()" :maxLength="7">
                        <template #enterButton>
                            <a-button>从物料库中选择</a-button>
                        </template>
                    </a-input-search>
                </a-form-item>
                <a-form-item label="选择批次" v-if="material_id != 0 && (mode == '单批次数量' || mode == '单批次时间')">
                    <a-row :gutter="20">
                        <a-col :span="12">
                            <a-select v-model:value="material_supplier_id" :options="material_supplier_list" placeholder="请选择供应商" @change="changeSupplier" allowClear />
                        </a-col>
                        <a-col :span="12">
                            <a-select v-model:value="material_batch_number" :options="material_batch_number_list" placeholder="请选择批号" @change="changeBatchNumber" allowClear />
                        </a-col>
                    </a-row>
                </a-form-item>
                <a-form-item label="预警值" v-if="material_id && mode == '总库存数量'">
                    <a-input style="text-align:right" v-model:value="alert_count" prefix="库存少于" :suffix="material_brief.unit" />
                </a-form-item>
                <a-form-item label="预警值" v-if="material_id && mode == '全批次数量'">
                    <a-input style="text-align:right" v-model:value="alert_count" prefix="库存少于" :suffix="material_brief.unit" />
                </a-form-item>
                <a-form-item label="预警值" v-if="material_supplier_id && material_batch_number && mode == '单批次数量'">
                    <a-input style="text-align:right" v-model:value="alert_count" prefix="库存少于" :suffix="material_brief.unit" />
                </a-form-item>
                <a-form-item label="有效期" v-if="material_supplier_id && material_batch_number && mode == '单批次时间'">
                    <a-date-picker v-model:value="alert_date" style="width:100%" />
                    <a-button-group style="display:flex">
                        <a-button style="flex-grow:1" @click="alert_date = moment().add(6, 'M')">半年</a-button>
                        <a-button style="flex-grow:1" @click="alert_date = moment().add(1, 'y')">一年</a-button>
                        <a-button style="flex-grow:1" @click="alert_date = moment().add(2, 'y')">两年</a-button>
                        <a-button style="flex-grow:1" @click="alert_date = moment().add(3, 'y')">三年</a-button>
                        <a-button style="flex-grow:1" @click="alert_date = moment().add(5, 'y')">五年</a-button>
                        <a-button style="flex-grow:1" @click="alert_date = moment().add(10, 'y')">十年</a-button>
                    </a-button-group>
                </a-form-item>
            </a-form>
        </a-modal>
        <!-- <a-row>
            <a-col :span="12">
                <a-form-item label="监测类型" :wrapperCol="{ span: 18 }">
                    <a-select v-model:value="mode" style="width:100%">
                        <a-select-option value="总库存数量">总库存数量</a-select-option>
                        <a-select-option value="全批次数量">全批次数量</a-select-option>
                        <a-select-option value="单批次数量">单批次数量</a-select-option>
                        <a-select-option value="单批次时间">单批次时间</a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>
            <a-col :span="12">
                <a-form-item label="监测类型" :wrapperCol="{ span: 18 }">
                    <a-select v-model:value="mode" style="width:100%">
                        <a-select-option value="总库存数量">总库存数量</a-select-option>
                        <a-select-option value="全批次数量">全批次数量</a-select-option>
                        <a-select-option value="单批次数量">单批次数量</a-select-option>
                        <a-select-option value="单批次时间">单批次时间</a-select-option>
                    </a-select>
                </a-form-item>
            </a-col>
        </a-row> -->
        <a-table :columns="table_columns" bordered :dataSource="table_datas" rowKey="id" :pagination="false">
            <template #operation="scoped">
                <a-button type="link" @click="deletePrewarning(scoped.record.id)">删除</a-button>
            </template>
        </a-table>
        <a-pagination style="display:flex;justify-content:center;margin-top:10px" show-size-changer v-model:current="page" v-model:pageSize="perpage" :total="total_count" @change="getData" @showSizeChange="getData" :pageSizeOptions="['10', '15', '20', '50']" v-if="total_count" />
        <SelectTotalMaterial ref="SelectTotalMaterial" dataType="code" :multiple="false" @finish="finishSelect" />
    </base-content>
</template>

<script>
import SelectTotalMaterial from '../components/modal/SelectTotalMaterial';
import moment from 'moment';
export default {
    components: {
        SelectTotalMaterial,
    },
    data() {
        return {
            moment,
            supplier_list: [],
            modal_visible: false,
            // 模式，分四种
            mode: '',
            // v-model绑定的物料编码
            material_code: '',
            // 在编码那一行提示相关信息
            material_extra: '',
            // 物料信息摘要
            material_brief: '',
            // 实际选中物料的id
            material_id: 0,
            // 实际选中物料的供应商id，在非单批次模式下不选
            material_supplier_id: null,
            // 物料批号，同上
            material_batch_number: null,
            // 可供选择的库存列表，用于供应商于批号之间相互制约
            material_inventory_list: [],
            // 用于选择的批号选项列表
            material_batch_number_list: [],
            // 同上，供应商选项列表
            material_supplier_list: [],
            alert_count: null,
            alert_date: '',
            page: 1,
            perpage: 10,
            total_count: 0,
            table_datas: [],
            table_columns: [
                { title: 'ID', dataIndex: 'id', width: 50, align: 'center' },
                { title: '物料名称', dataIndex: 'material_name' },
                { title: '物料编码', dataIndex: 'material_code' },
                { title: '监测方式', dataIndex: 'type' },
                { title: '预警值', dataIndex: 'alert_value', align: 'center' },
                { title: '操作', align: 'center', width: 70, slots: { customRender: 'operation' } },
            ],
        };
    },
    watch: {
        material_code(value) {
            let flag = false;
            if (value.length != 7) return;
            value = value.toUpperCase();
            value = value.split('-');
            if (value.length != 2 || value[0].length != 2 || value[1].length != 4) flag = '不正确的物料编码';
            if (flag) {
                this.material_extra = flag;
                this.material_id = 0;
                this.material_code = '';
            } else {
                this.$api('getMaterialBrief', { prefix: value[0], suffix: this._32to10(value[1]) }).then((res1) => {
                    if (res1.result) {
                        this.material_brief = res1.material_brief;
                        this.material_id = res1.material_brief.material_id;
                        this.material_extra = `已选物料名称：${res1.material_brief.name}`;
                        this.material_code = value.join('-');
                        this.$api('getMaterialInventoryList', { material_id: this.material_id, inventory: false }).then((res2) => {
                            this.material_inventory_list = res2.inventory_list;
                            let supplier_list = [];
                            let batch_number_list = [];
                            res2.inventory_list.forEach((row) => {
                                let supplier_flag = true;
                                supplier_list.forEach((item) => {
                                    if (item.value == row.supplier_id) supplier_flag = false;
                                });
                                if (supplier_flag)
                                    this.supplier_list.forEach((item) => {
                                        if (item.supplier_id == row.supplier_id) supplier_list.push({ value: item.supplier_id, label: item.name });
                                    });
                                let batch_number_flag = true;
                                batch_number_list.forEach((item) => {
                                    if (item.value == row.batch_number) batch_number_flag = false;
                                });
                                if (batch_number_flag) batch_number_list.push({ value: row.batch_number, label: row.batch_number });
                            });
                            this.material_supplier_list = supplier_list;
                            this.material_batch_number_list = batch_number_list;
                        });
                    } else {
                        this.material_id = 0;
                        this.material_extra = '不存在的物料编号';
                        this.material_code = '';
                    }
                });
            }
        },
    },
    methods: {
        _10to32(num10) {
            num10 = parseInt(num10);
            if (num10 < 0 || num10 > 1048575) {
                throw new Error('用于转换的10进制数太小或太大，仅限0~1048575，而你传入了：' + num10);
            }
            let map = '0123456789ABCEFGHJKLMNPQRSTUVWXY';
            let str32 = '';
            while (true) {
                str32 = map[num10 % 32] + str32;
                num10 = parseInt(num10 / 32);
                if (num10 == 0) {
                    return ('000' + str32).slice(-4);
                }
            }
        },
        _32to10(str32) {
            str32 = String(str32);
            if (str32.length > 4 || str32.length < 1) {
                throw new Error('用于转换的32进制数太小或太大，仅限0000~YYYY，而你传入了：' + str32);
            }
            let num10 = 0;
            let map = '0123456789ABCEFGHJKLMNPQRSTUVWXY';
            for (let i = 0; i < str32.length; i++) {
                let index = map.indexOf(str32[i]);
                if (index == -1) {
                    throw new Error('用于转换的32进制数字符不符，仅限0123456789ABCEFGHJKLMNPQRSTUVWXY，而你传入了：' + str32[i]);
                }
                num10 += index * 32 ** (str32.length - 1 - i);
            }
            return num10;
        },
        getData(ban_cache = false) {
            this.$api('getPrewarningList', { page: this.page, perpage: this.perpage }, ban_cache).then((res) => {
                this.total_count = res.total_count;
                res.prewarning_list.forEach((item) => {
                    item.material_code = item.prefix + '-' + this._10to32(item.suffix);
                    item.alert_value = item.type == '单批次时间' ? `日期接近 ${item.alert_value} 时` : `库存少于 ${item.alert_value} ${item.unit}时`;
                });
                this.table_datas = res.prewarning_list;
            });
        },
        finishSelect(material_code) {
            this.material_code = material_code[0];
        },
        changeSupplier(supplier_id) {
            // 可供选择的批号列表
            let could_select_batch_number_list = [];
            this.material_inventory_list.forEach((item) => {
                if (supplier_id == undefined || supplier_id == item.supplier_id) could_select_batch_number_list.push(item.batch_number);
            });
            // 将所有不可选择的选项置为禁用
            this.material_batch_number_list.forEach((item) => {
                item.disabled = could_select_batch_number_list.indexOf(item.value) == -1;
            });
        },
        changeBatchNumber(batch_number) {
            // 原理同 changeSupplier
            let could_select_supplier_id_list = [];
            this.material_inventory_list.forEach((item) => {
                if (batch_number == undefined || batch_number == item.batch_number) could_select_supplier_id_list.push(item.supplier_id);
            });
            this.material_supplier_list.forEach((item) => {
                item.disabled = could_select_supplier_id_list.indexOf(item.value) == -1;
            });
        },
        submit() {
            let flag = false;
            if (!flag && this.mode == '') flag = '请选择预警形式';
            if (!flag && !this.material_id) flag = '请选择要监测的物料';
            if (!flag && this.mode == '单批次数量' && (!this.material_supplier_id || !this.material_batch_number)) flag = '请选择物料批次';
            if (!flag && this.mode != '单批次时间' && !this.alert_count) flag = '请输入预警值';
            if (!flag && this.mode != '单批次时间' && isNaN(Number(this.alert_count))) flag = '请输入正确的预警值';
            if (!flag && this.mode != '单批次时间' && Number(this.alert_count) <= 0) flag = '输入的预警值必须大于0';
            if (!flag && this.mode == '单批次时间' && (!this.material_supplier_id || !this.material_batch_number)) flag = '请选择物料批次';
            if (!flag && this.mode == '单批次时间' && !this.alert_date) flag = '请选择该批次有效期';
            if (flag) return this.$message.error(flag);
            let type = this.mode;
            let material_id = this.material_id;
            let material_key = type.indexOf('单批次') == -1 ? material_id : `${material_id}_${this.material_supplier_id}_${this.material_batch_number}`;
            let alert_value = type == '单批次时间' ? this.alert_date.unix() : this.alert_count;
            this.$api('addPrewarning', { type, material_id, material_key, alert_value }).then((res) => {
                if (res.result) {
                    this.$message.success('添加成功');
                    this.mode = null;
                    this.material_id = 0;
                    this.material_code = '';
                    this.material_supplier_id = null;
                    this.material_batch_number = null;
                    this.alert_count = null;
                    this.alert_date = null;
                    this.modal_visible = false;
                    this.getData(true);
                } else {
                    this.$message.error(res.message);
                }
            });
        },
        deletePrewarning(prewarning_id) {
            this.$modal.confirm({
                title: '请确认',
                content: '确定要删除该条记录吗？',
                onOk: () => {
                    this.$api('deletePrewarning', { prewarning_id }).then((res) => {
                        if (res.result) {
                            this.$message.success('操作成功');
                            this.getData(true);
                        } else {
                            this.$message.error('操作失败');
                        }
                    });
                },
            });
            // console.log(prewarning_id);
        },
    },
    mounted() {
        this.$api('getTotalSupplierList').then((res) => {
            this.supplier_list = res.supplier_list;
        });
        this.getData();
    },
};
</script>

<style></style>
